import React from 'react';

import Layout from '../containers/Layout';
import Search from '../containers/Search';

const CategoriesPage = ({ pageContext, _, location }) => (
  <Layout title={pageContext.category} description={`Posts about ${pageContext.category}`} location={location.href}>
    <Search category={pageContext.category} />
  </Layout>
);

export default CategoriesPage;
